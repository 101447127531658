import React, {lazy, useEffect} from 'react';
import {Layout, Alert, Row, Col, Card, Button, Typography, Input, Spin, Image, Breadcrumb} from 'antd';
import {FormattedMessage} from 'react-intl';
import OwnLayout from "../layout/OwnLayout";
import {Link, useLocation} from "react-router-dom";
import pageData from "../../assets/data/data.json";

const { Content } = Layout;
const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;


export default function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <OwnLayout>
            <Content>
                <div className="site-layout-background">
                    <Row justify={'center'}>
                        <Col xs={24} sm={22} md={20} lg={23} xl={23}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to={'/'}>Home</Link>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={[8, 16]}>
                        {Object.keys(pageData['de'].products).map((value, index) => {

                            return (
                                <Col xs={12} sm={12} md={12} lg={8} xl={6} key={index}>
                                    <Row justify={'center'}>
                                        <Col xs={24} sm={20} md={16} lg={20} xl={20}>
                                            <Link to={'/'+value+'/'}>
                                                <Card
                                                    hoverable
                                                    className={'home-card-style'}
                                                    cover={
                                                        <div
                                                            style={{textAlign: 'center'}}
                                                        >
                                                            <Image
                                                                preview={false}
                                                                placeholder={
                                                                    <Spin />
                                                                }
                                                                style={{width: '100%', maxWidth: 250, height: 'auto', padding: 5}}
                                                                src={'/assets/products/'+pageData['de'].products[value].image}
                                                            />
                                                        </div>
                                                    }
                                                >
                                                    <Meta
                                                        title={
                                                            <Text style={{whiteSpace: "pre-wrap"}}>
                                                                {pageData['de'].products[value].title}
                                                            </Text>
                                                        }
                                                    />
                                                </Card>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                            );

                        })}
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </Content>
        </OwnLayout>
    );
}
