import React, {lazy, useEffect} from 'react';
import {Layout, Alert, Row, Col, Card, Button, Typography, Input, Spin, Image, Breadcrumb} from 'antd';
import {FormattedMessage} from 'react-intl';
import OwnLayout from "../layout/OwnLayout";
import {Link, useLocation} from "react-router-dom";
import newsData from "../../assets/data/news.json";
import {setSideMenuState} from "../../data/actions/layout_actions";

const { Content } = Layout;
const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;


export default function News() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <OwnLayout>
            <Content>
                <div className="site-layout-background">
                    <Row justify={'center'}>
                        <Col xs={24} sm={22} md={20} lg={23} xl={23}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to={'/news'}>News</Link>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={[8, 16]}>
                        {Object.keys(newsData['de']).map((value, index) => {

                            return (
                                <Col xs={12} sm={12} md={12} lg={8} xl={6} key={index}>
                                    <Row justify={'center'}>
                                        <Col xs={24} sm={20} md={16} lg={20} xl={20}>
                                                <Card
                                                    hoverable
                                                    cover={
                                                        <div
                                                            style={{textAlign: 'center'}}
                                                        >
                                                            <Image
                                                                preview={false}
                                                                placeholder={
                                                                    <Spin />
                                                                }
                                                                style={{width: '100%', maxWidth: 250, height: 'auto', padding: 5}}
                                                                src={'/assets/products/'+newsData['de'][value].image}
                                                            />
                                                        </div>
                                                    }
                                                >
                                                    <Meta
                                                        title={
                                                            <>
                                                                <Text style={{whiteSpace: "pre-wrap"}}>
                                                                    {newsData['de'][value].name}
                                                                </Text>
                                                                <br/>
                                                                <Text style={{whiteSpace: "pre-wrap", fontSize: 16}}>
                                                                    <b>
                                                                        {newsData['de'][value].desc}
                                                                    </b>
                                                                </Text>
                                                                <br/>
                                                                <br/>
                                                                <Link
                                                                    to={{ pathname: newsData['de'][value].amzlink }}
                                                                    target="_blank"
                                                                >
                                                                    <Button type="primary" size="large" block>
                                                                        Zum Shop
                                                                    </Button>
                                                                </Link>
                                                            </>
                                                        }
                                                    />
                                                </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            );

                        })}
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </Content>
        </OwnLayout>
    );
}
