import React, {lazy, useEffect} from 'react';
import {
    Layout,
    Alert,
    Row,
    Col,
    Card,
    Button,
    Typography,
    Input,
    Select,
    Image,
    Space,
    Breadcrumb,
    Spin,
    BackTop
} from 'antd';
import {FormattedMessage} from 'react-intl';
import OwnLayout from "../layout/OwnLayout";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import pageData from "../../assets/data/data.json";
import {LeftOutlined} from "@ant-design/icons";

const { Content } = Layout;
const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;


export default function Categorys() {

    const history = useHistory();
    const {product} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(pageData['de'].products[product] === undefined){
        history.push("/");
        return false;
    }else {
        return (
            <OwnLayout>
                <Content>
                    <div className="site-layout-background">
                        <Row justify={'center'}>
                            <Col xs={24} sm={22} md={20} lg={23} xl={23}>
                                <Button type="primary" icon={<LeftOutlined />} ghost style={{marginBottom: 10}} onClick={history.goBack}/>
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <Link to={'/'}>Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={'/'+product+'/'}>{pageData['de'].products[product].title}</Link>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        {Object.keys(pageData['de'].products[product].cats).map((value1, index1) => {

                            return (
                                <div key={index1}>
                                    <Row justify={'center'}>
                                        <Col xs={24} sm={22} md={22} lg={23} xl={23}>
                                            <Title level={3}>{pageData['de'].products[product].cats[value1].title}</Title>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 8]}>
                                        {Object.keys(pageData['de'].products[product].cats[value1]['sub-cats']).map((value2, index2) => {

                                            return(
                                                <Col xs={12} sm={12} md={12} lg={8} xl={6} key={index2}>
                                                    <Row justify={'center'}>
                                                        <Col xs={24} sm={20} md={16} lg={20} xl={20}>
                                                            <Link to={'/'+product+'/'+value1+'/'+value2+'/'}>
                                                                <Card
                                                                    hoverable
                                                                    className={'cat-card-style'}
                                                                    cover={
                                                                        <div
                                                                            style={{textAlign: 'center'}}
                                                                        >
                                                                            <Image
                                                                                preview={false}
                                                                                placeholder={
                                                                                    <Spin />
                                                                                }
                                                                                style={{width: '100%', maxWidth: 250, height: 'auto', padding: 5}}
                                                                                src={'/assets/products/'+pageData['de'].products[product].cats[value1]['sub-cats'][value2].image}
                                                                            />
                                                                        </div>
                                                                    }
                                                                >
                                                                    <Meta
                                                                        title={pageData['de'].products[product].cats[value1]['sub-cats'][value2].title}
                                                                        //description={pageData['de'].products[product].cats[value1]['sub-cats'][value2].desc}
                                                                    />
                                                                </Card>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            );

                                        })}
                                    </Row>
                                    <br/>
                                </div>
                            );

                        })}
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </Content>
                <BackTop />
            </OwnLayout>
        );
    }
}
