import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, useParams, Redirect, useHistory
} from "react-router-dom";

import 'antd/dist/antd.css';
import './assets/App.css';

import {Button, Col, Result, Row} from "antd";

import { Cookies } from 'react-cookie';

// Private routes

import Home from "./pages/home/Home";
import Categorys from "./pages/categorys/Categorys";
import Videos from "./pages/videos/Videos";
import News from "./pages/news/News";

import PublicRoute from './components/router/PublicRoute';
import PrivateRoute from './components/router/PrivateRoute';

import {Provider, useDispatch, useSelector} from "react-redux";
import {IntlProvider} from 'react-intl'

import messages_de from "./lang/de.json";
import messages_en from "./lang/en.json";


const messages = {
    'de': messages_de,
    'en': messages_en
};

export default function App() {

    const getLang = useSelector(state => state.getLang);

    return (
        <IntlProvider messages={messages[getLang]} locale={getLang} defaultLocale="de">
            <Router>
                <Switch>
                    <PublicRoute restricted={false} component={Home} path="/" exact />
                    <PublicRoute restricted={false} component={News} path="/news" exact />
                    <PublicRoute restricted={false} component={Categorys} path="/:product" exact />
                    <PublicRoute restricted={false} component={Videos} path="/:product/:cat/:subcat" exact />
                    <PublicRoute restricted={true} path="*" exact >
                        <NoRoute />
                    </PublicRoute>
                </Switch>
            </Router>
        </IntlProvider>
    );
}

function NoRoute() {

    const history = useHistory();
    history.push("/");

    return false;
}

