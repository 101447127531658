import React, {lazy, useEffect} from 'react';
import {Layout, Alert, Row, Col, Card, Button, Typography, Input, Empty, Image, BackTop, Breadcrumb, Spin} from 'antd';
import {FormattedMessage} from 'react-intl';
import {LeftOutlined, LinkOutlined} from "@ant-design/icons";
import OwnLayout from "../layout/OwnLayout";
import {Link, useHistory, useParams} from "react-router-dom";

import Player from "../components/Player";
import pageData from "../../assets/data/data.json";

//const TestPlayer = React.lazy(() => import("../components/Player"));

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;


export default function Videos() {

    const history = useHistory();
    const {product, cat, subcat} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function renderData(){

        return(
            <>
                {pageData['de'].products[product].cats[cat]['sub-cats'][subcat].videos.length >= 1 && Object.keys(pageData['de'].products[product].cats[cat]['sub-cats'][subcat].videos).map((value1, index1) => {

                    let title = pageData['de'].products[product].cats[cat]['sub-cats'][subcat].videos[value1].title;

                        return(
                            <Row key={index1} justify={'center'}>
                                <Col xs={24} sm={20} md={16} lg={20} xl={20}>
                                    <Card title={title} style={{marginBottom: 40}}>
                                        {pageData['de'].products[product].cats[cat]['sub-cats'][subcat].videos[value1].videoid != '123' ?
                                            <Player
                                                playerData={{
                                                    videoHash: pageData['de'].products[product].cats[cat]['sub-cats'][subcat].videos[value1].videoid
                                                }}
                                            />
                                            :
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        }
                                        {/*
                                        <Text style={{whiteSpace: "pre-wrap"}}>
                                            {pageData['de'].products[product].cats[cat]['sub-cats'][subcat].videos[value1].desc}
                                        </Text>
                                        */}
                                    </Card>
                                </Col>
                            </Row>
                        )

                    })}

                <div>
                    {pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra !== undefined && pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.title !== undefined
                        ?
                        <Row justify={'center'}>
                            <Col xs={22} sm={20} md={16} lg={12} xl={12}>
                                <Title>{pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.title}</Title>
                            </Col>
                        </Row>
                        :
                        null}

                    {pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra !== undefined && pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.image !== undefined
                        ?
                        <Row justify={'center'}>
                            <Col xs={24} sm={20} md={16} lg={20} xl={20}>
                                <div
                                    style={{textAlign: 'center'}}
                                >
                                <Image
                                    preview={false}
                                    style={{width: '100%', maxWidth: 350, height: 'auto', padding: 5}}
                                    placeholder={
                                        <Spin />
                                    }
                                    src={'/assets/products/'+pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.image}
                                />
                                </div>
                            </Col>
                        </Row>
                        :
                        null}

                    {pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra !== undefined && pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.desc !== undefined
                        ?
                        <Row justify={'center'}>
                            <Col xs={22} sm={20} md={16} lg={12} xl={12}>
                                <Text style={{whiteSpace: "pre-wrap"}}>{pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.desc}</Text>
                            </Col>
                        </Row>
                        :
                        null}

                    {pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra !== undefined && pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.links !== undefined
                        ?
                        Object.keys(pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.links).map((value1, index1) => {

                                return(
                                    <>
                                        <br/>
                                        <Row key={index1} justify={'center'}>
                                            <Col style={{paddingBottom: 10}}>
                                                <a href={pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.links[value1].url} target={'_blank'}>
                                                    <Button type="primary">
                                                        {pageData['de'].products[product].cats[cat]['sub-cats'][subcat].extra.links[value1].title}
                                                    </Button>
                                                </a>
                                            </Col>
                                        </Row>
                                    </>
                                )

                            })
                        :
                        null}

                </div>

            </>
        )

    }

    if(pageData['de'].products[product].cats[cat]['sub-cats'][subcat] === undefined){
        history.push("/");
        return false;
    }else{
        return (
            <OwnLayout>
                <Content>
                    <div className="site-layout-background">
                        <Row justify={'center'}>
                            <Col xs={24} sm={22} md={20} lg={23} xl={23}>
                                <Button type="primary" icon={<LeftOutlined />} ghost style={{marginBottom: 10}} onClick={history.goBack}/>
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <Link to={'/'}>Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={'/'+product+'/'}>{pageData['de'].products[product].title}</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={'/'+product+'/'}>{pageData['de'].products[product].cats[cat]['sub-cats'][subcat].title}</Link>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <div style={{paddingTop: 30}}>
                            {renderData()}
                        </div>
                    </div>
                </Content>
                <BackTop />
            </OwnLayout>
        );
    }
}
