import {isSidebarCollapsed} from "./layout";
import {isLang} from "./user";
import {combineReducers} from 'redux';

const allReducers = combineReducers({
    getLang: isLang,
    sidemenustate: isSidebarCollapsed,
 });

export default allReducers;
