import React from 'react';
import { hydrate, render } from "react-dom";
import { ConfigProvider } from 'antd';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { CookiesProvider } from 'react-cookie';

import { createStore } from 'redux';
import allReducers from './data/reducers'
import {Provider} from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const appStore = createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(
        <CookiesProvider>
            <Provider store={appStore}>
                <ConfigProvider prefixCls="pls">
                    <App />
                </ConfigProvider>
            </Provider>
        </CookiesProvider>
    , rootElement);
} else {
    render(
        <CookiesProvider>
            <Provider store={appStore}>
                <ConfigProvider prefixCls="pls">
                    <App />
                </ConfigProvider>
            </Provider>
        </CookiesProvider>
    , rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
