export const toggleSideMenuState = () => {
    return {
        type: 'toggle'
    };
}

export const setSideMenuState = (data) => {
    return {
        type: 'setState',
        payload: data
    };
}
