export const isSidebarCollapsed = (state = false, action) => {
    switch (action.type){
        case 'toggle':
            return !state;
        case 'setState':
            return state = action.payload;
        default:
            return state;
    }
};
