import React from 'react';
import {
    Layout,
    Avatar,
    Badge,
    Row,
    Col,
    Dropdown,
    Typography,
    Select,
    Card,
    List,
    Drawer,
    Space,
    Button, Image, Menu
} from 'antd';
import {
    HomeOutlined,
    BellOutlined,
    BulbOutlined,
    FolderOpenOutlined,
    NotificationOutlined,
    MenuFoldOutlined,
    QuestionCircleOutlined,
    MenuUnfoldOutlined,
    ShoppingOutlined,
    DashboardOutlined,
    MenuOutlined,
    BarChartOutlined,
    KeyOutlined,
    FileSearchOutlined,
    SearchOutlined,
    LayoutOutlined,
    LinkOutlined,
    ReadOutlined,
    TeamOutlined,
    MessageOutlined,
    SoundOutlined,
    ExclamationOutlined
} from '@ant-design/icons';

import {useDispatch, useSelector} from 'react-redux';
import {setLang} from "../../data/actions";
import {toggleSideMenuState, setSideMenuState} from "../../data/actions/layout_actions"
import {Link, useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import ReactCountryFlag from "react-country-flag";


// TODO Pläne einfügen
// TODO Shop zu Amzon
// TODO Shop in zürock möglich in ios
// TODO Bilder richtig zu ordern
// TODO Videos hinzufügen
// TODO Push notification
// TODO Sing & Kinesio kategorie entfernen/überspringen


import logo from "../../assets/images/pulsus-logo-white.png";
const { Text, Paragraph } = Typography;
const { Header, Sider, Footer } = Layout;
const { Option } = Select;
const { SubMenu } = Menu;


function OwnHeader(){

    const getLang = useSelector(state => state.getLang);
    const sidemenustate = useSelector(state => state.sidemenustate);

    const dispatch = useDispatch();

    function changeLang(value) {
        dispatch(setLang(value));
        //console.log('setLang: '+value);
    }

    return (
        <Header style={{padding: 0}}>
            <Row>
                <Col span={4}>
                    <Row justify={'start'} align={'middle'} style={{paddingLeft: 30, paddingRight: 10}}>
                        <Col>
                            <Space>
                                {sidemenustate ?
                                    <MenuUnfoldOutlined onClick={() => {
                                        dispatch(toggleSideMenuState())
                                    }} style={{fontSize: '28px', color: 'white', paddingTop: '18px'}}/>
                                    :
                                    <Badge count={7} size="small" offset={[5, 20]}>
                                        <MenuFoldOutlined onClick={() => {
                                            dispatch(toggleSideMenuState())
                                        }} style={{fontSize: '28px', color: 'white', paddingTop: '18px'}}/>
                                    </Badge>
                                }
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col span={16}>
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                            <div className="logo">
                                <Link to="/">
                                    <Image
                                        preview={false}
                                        width={150}
                                        src={logo}
                                    />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Row justify={'end'} align={'middle'}>
                        <Col style={{paddingRight: 70}}>
                            <Select
                                defaultValue={getLang}
                                bordered={false}
                                showArrow={false}
                                style={{color: 'white', paddingBottom: 24, width: 70}}
                                dropdownMatchSelectWidth={false}
                                placement={'bottomRight'}
                                onChange={changeLang}>
                                    <Option value="de">
                                        <ReactCountryFlag
                                            countryCode={'de'}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{marginRight: 5, marginBottom: 5}}
                                        />
                                        DE
                                    </Option>
                                    <Option value="en" disabled>
                                        <ReactCountryFlag
                                            countryCode={'gb'}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{marginRight: 5, marginBottom: 5}}
                                        />
                                        EN (soon)
                                    </Option>
                                    <Option value="es" disabled>
                                        <ReactCountryFlag
                                            countryCode={'es'}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{marginRight: 5, marginBottom: 5}}
                                        />
                                        ES (soon)
                                    </Option>
                                    <Option value="fr" disabled>
                                        <ReactCountryFlag
                                            countryCode={'fr'}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{marginRight: 5, marginBottom: 5}}
                                        />
                                        FR (soon)
                                    </Option>
                                    <Option value="it" disabled>
                                        <ReactCountryFlag
                                            countryCode={'it'}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{marginRight: 5, marginBottom: 5}}
                                        />
                                        IT (soon)
                                    </Option>
                                    <Option value="nl" disabled>
                                        <ReactCountryFlag
                                            countryCode={'nl'}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{marginRight: 5, marginBottom: 5}}
                                        />
                                        NL (soon)
                                    </Option>
                                    <Option value="pl" disabled>
                                        <ReactCountryFlag
                                            countryCode={'pl'}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{marginRight: 5, marginBottom: 5}}
                                        />
                                        PL (soon)
                                    </Option>
                                    <Option value="sv" disabled>
                                        <ReactCountryFlag
                                            countryCode={'sv'}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{marginRight: 5, marginBottom: 5}}
                                        />
                                        SV (soon)
                                    </Option>
                                </Select>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </Header>
    );
}


function OwnSidebar2(){

    const dispatch = useDispatch();
    const sidemenustate = useSelector(state => state.sidemenustate);

    const location = useLocation();
    const path = location.pathname;
    const splitOne = path.split('/');
    const pathAsArray = splitOne.slice(1);

    let menuSelect = '';
    let submenuSelect = '';

    if(pathAsArray.length > 1){
        submenuSelect = pathAsArray[0];
        menuSelect = pathAsArray[1];
    }else{
        menuSelect = pathAsArray[0];
    }

    if(menuSelect == undefined || menuSelect == ''){
        menuSelect = 'home';
    }

    return(
        <Drawer
            placement="left"
            visible={sidemenustate}
            onClose={()=>dispatch(setSideMenuState(false))}
            bodyStyle={{padding: 'unset'}}
            width={250}
        >
            <Menu theme="light" mode="inline" defaultSelectedKeys={[menuSelect]} defaultOpenKeys={[submenuSelect]}>
                <Menu.Item key="home" icon={<HomeOutlined />}>
                    <Link
                        to={'/'}
                        onClick={()=>{dispatch(setSideMenuState(false))}}
                    >
                        <FormattedMessage id="app.menu.home" />
                    </Link>
                </Menu.Item>
                {/*
                <Menu.Item key="home">
                    <Link to="/">
                        <FormattedMessage id="app.menu.home" />
                    </Link>
                </Menu.Item>
                <Menu.Item key="dashboard">
                    <Link to="/dashboard">
                        <b>
                            <FormattedMessage id="app.menu.dashboard" />
                        </b>
                    </Link>
                </Menu.Item>
                <Menu.Item key="projects">
                    <Link to="/projects">
                        <b>
                            <FormattedMessage id="app.menu.projects" />
                        </b>
                    </Link>
                </Menu.Item>

                <SubMenu key="traffic-analyzer"  title="TRAFFIC ANALYZER">
                    <Menu.Item key="overview" >
                        <Link to="/traffic-analyzer/overview">Overview (X)</Link>
                    </Menu.Item>
                    <Menu.Item key="top-page" >
                        <Link to="/traffic-analyzer/top-page">Top Page (X)</Link>
                    </Menu.Item>
                    <Menu.Item key="keywords" >
                        <Link to="/traffic-analyzer/keywords">Key Words (X)</Link>
                    </Menu.Item>
                </SubMenu>

                */}

                <Menu.Item key="news" icon={<ExclamationOutlined />}>
                    <Link
                        to="/news"
                        onClick={()=>{dispatch(setSideMenuState(false))}}
                    >
                        <Badge count={7} size={'small'} offset={[10, 0]}>
                            <b>News</b>
                        </Badge>
                    </Link>
                </Menu.Item>
                <Menu.Item key="17" icon={<ShoppingOutlined />}>
                    <Link
                        to={{ pathname: "https://pulsus-fit.de/produkte/" }}
                        target="_blank"
                        onClick={()=>{dispatch(setSideMenuState(false))}}
                    >
                        <b>Shop</b>
                    </Link>
                </Menu.Item>
            </Menu>
        </Drawer>
    );
}


export default function OwnLayout(props) {
        return (
            <Layout className="site-layout" style={{minHeight: '1000px'}}>
                <OwnHeader />
                <OwnSidebar2 />
                {props.children}
                {/*
                <div style={{backgroundColor: "red", position: "fixed", left: 0, right: 0, bottom: 0, height: 50}}>
                    <Row justify={"center"} align={"middle"}>
                        <Col>
                            <Text>
                                Test
                            </Text>
                        </Col>
                    </Row>
                </div>
                */}
            </Layout>
        );
}
