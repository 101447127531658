import React, {createRef, useEffect, useState, lazy} from 'react';
import {
    Layout,
    Alert,
    Row,
    Col,
    Card,
    Progress,
    Button,
    Typography,
    Input,
    Upload,
    Image,
    Tag,
    Divider, Carousel, Space, Spin
} from 'antd';
import {AlertOutlined, SendOutlined, WifiOutlined, SketchOutlined, StarFilled} from "@ant-design/icons";
import ReactPlayer from "react-player";


const { Title, Text, Paragraph } = Typography;
const { Content } = Layout;

export default function Player(props) {

    const player = createRef();

    const [showPlayer, setShowPlayer] = useState(true);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [duration, setDuration] = useState(0);
    const [time, setTime] = useState(0);

    let videoDuration = 0;


    useEffect(() => {


    }, []);

    function onClick(){

        if(true){

        }else{

        }
    }


    return (
        <>
            {(videoLoaded
                ?
                    null
                :
                    <Row justify={'center'}>
                        <Col>
                            <Spin>
                                <br/>
                                <br/>
                                <Text>
                                    Video loading ...
                                </Text>
                            </Spin>
                        </Col>
                    </Row>
            )}
            <div style={(videoLoaded ? { position: "relative", paddingTop: "56.25%"} : null)}>
                <ReactPlayer
                    ref={player}
                    className={'react-player'}
                    width={(videoLoaded ? "100%" : 0)}
                    height={(videoLoaded ? "100%" : 0)}
                    url={'https://www.youtube.com/watch?v='+props.playerData.videoHash}
                    controls={true}
                    onProgress={(data)=>{
                        if(videoDuration > 0){
                            if((videoDuration - data.playedSeconds) < 2){
                                player.current.seekTo(0);
                            }
                        }
                    }}
                    onReady={()=>{
                        console.log('Video loaded');
                        setVideoLoaded(true);
                    }}
                    onDuration={(data)=>{
                        videoDuration = data;
                    }}
                    config={{
                        youtube: {
                            playerVars: {
                                modestbranding: 1,
                                rel: 0
                            }
                        }
                    }}
                />
            </div>
            <br/>
        </>
    );
}
